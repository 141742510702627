import footer01_img from "../../assets/images/ico_footer01.png";
import footer02_img from "../../assets/images/ico_footer02.png";
import footer03_img from "../../assets/images/ico_footer03.png";
import footer04_img from "../../assets/images/ico_footer04.png";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="top">
          <strong>Official Links</strong>
          <ul className="footer-sns">
            <li>
              <a href="https://twitter.com/hero_ducks" target="_blank">
                <img src={footer01_img} alt="" />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/kXFsEatpQ6" target="_blank">
                <img src={footer02_img} alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/hero.ducks/" target="_blank">
                <img src={footer03_img} alt="" />
              </a>
            </li>

            <li>
              <a href="https://treasurelabs.io/" target="_blank">
                <img src={footer04_img} alt="" />
              </a>
            </li>
          </ul>
        </div>
        <div className="copy">
          <strong>HERO DUCK</strong>
          <p>
            © Hero Duck. All Rights Reserved. |{" "}
            <a href="https://url.kr/2ubt8x " target="_blank">
              Terms & Conditions
            </a>{" "}
            |{" "}
            <a href="https://url.kr/2ubt8x " target="_blank">
              Privacy Policy
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
