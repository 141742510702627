import kv from "../../assets/images/img_kv.jpg";
import nft01 from "../../assets/images/img_nft01.png";
import nft02 from "../../assets/images/img_nft02.png";
import nft03 from "../../assets/images/img_nft03.png";
import nft04 from "../../assets/images/img_nft04.png";
import nft05 from "../../assets/images/img_nft05.png";
import nft06 from "../../assets/images/img_nft06.png";
import heroduck_01_img from "../../assets/images/heroduck_1.jpg";
import heroduck_02_img from "../../assets/images/heroduck_2.jpg";
import heroduck_03_img from "../../assets/images/heroduck_3.jpg";
import heroduck_04_img from "../../assets/images/heroduck_4.jpg";
import heroduck_05_img from "../../assets/images/heroduck_5.jpg";
import heroduck_06_img from "../../assets/images/heroduck_6.jpg";
import heroduck_07_img from "../../assets/images/heroduck_7.jpg";
import heroduck_08_img from "../../assets/images/heroduck_8.jpg";
import heroduck_09_img from "../../assets/images/heroduck_9.jpg";
import heroduck_10_img from "../../assets/images/heroduck_10.jpg";

import heroduck_main_mp4 from "../../assets/video/mainp-logo_motion-md1080p.mp4";
import heroduck_sub_mp4 from "../../assets/video/heroduck_4k.mp4";
import heroduck_main_mobile_mp4 from "../../assets/video/motion-loop-4k.mp4";
import manual_pdf from "../../assets/pdf/HERODUCK-WEB.pdf";
import new_mobile_video from "../../assets/video/heroduck_motion.mp4";

import img_banner from "../../assets/images/img_banner.jpg";
import btn_buy_img from "../../assets/images/btn_buy.png";
import text_road_map_img from "../../assets/images/text_roadmap.png";
import road_map_ko_img from "../../assets/images/img-roadmap.png";
import text_road_map_img_m from "../../assets/images/text_roadmap-m.png";
import text_road_map_img_m_ko from "../../assets/images/roadmap-content-ko.png";
import text_chart_img from "../../assets/images/tresures-organization-img.png";
import text_chart_img_m from "../../assets/images/tresures-organization-m.png";
import Countdown from "../../components/Countdown";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import $ from "jquery";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { currentLangAtom } from "../../recoil/atom";

const Home = () => {
  const [select, setSelect] = useState(0);
  const [render, setRender] = useState(true);
  const { t } = useTranslation();

  const qnaLists = [
    {
      idx: 1,
      question: t(`question_06`),
      answer: <p>{t(`answer_06`)}</p>,
    },

    {
      idx: 2,
      question: t(`question_01`),
      answer: <p>{t(`answer_01`)}</p>,
    },

    {
      idx: 3,
      question: t(`question_02`),
      answer: <p>{t(`answer_02`)}</p>,
    },

    {
      idx: 4,
      question: t(`question_03`),
      answer: <p>{t(`answer_03`)}</p>,
    },

    {
      idx: 5,
      question: t(`question_04`),
      answer: <p>{t(`answer_04`)}</p>,
    },

    {
      idx: 6,
      question: t(`question_05`),
      answer: <p>{t(`answer_05`)}</p>,
    },
  ];
  const styleBorderRadius = useMemo(() => {
    return { borderRadius: "16px" };
  }, []);
  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  const currentLang = useRecoilValue(currentLangAtom);
  useEffect(() => {
    if (render) {
      var ss = $(".swiper-wrapper li");
      console.log("ss", ss);
      $(".btn_prev").on("click", function (e) {
        e.preventDefault();
      });
      $(".btn_prev").on("click", function (e) {
        for (var i = 0; i < ss.length; i++) {
          console.log(i);
          if ($(".swiper-wrapper").children[i] === $(".current-step")) {
            var s = i;
            if (i == ss.length - 1) {
              $(".swiper-wrapper").children[0].classList.remove("next-step");
            } else {
              $(".swiper-wrapper").children[i + 1].classList.remove("next-step");
            }

            $(".current-step").classList.add("next-step");
            $(".current-step").classList.remove("current-step");
            $(".prev-step").classList.add("current-step");
            $(".prev-step").classList.remove("prev-step");

            if (i == 0) {
              var s = ss.length - 2;
              $(".swiper-wrapper").children[s].classList.add("prev-step");
            } else {
              if (i == 1) {
                $(".swiper-wrapper").children[ss.length - 1].classList.add("prev-step");
              } else {
                $(".swiper-wrapper").children[i - 2].classList.add("prev-step");
              }
            }
            break;
          }
        }
      });

      $(".btn_next").on("click", function (e) {
        e.preventDefault();
        for (var i = 0; i < ss.length; i++) {
          if ($(".swiper-wrapper").children[i] === $(".current-step")) {
            var s = i;
            if (i == 0) {
              s = ss.length;
              $(".swiper-wrapper").children[s - 1].classList.remove("prev-step");
            } else {
              $(".swiper-wrapper").children[i - 1].classList.remove("prev-step");
            }

            $(".current-step").classList.add("prev-step");
            $(".current-step").classList.remove("current-step");
            $(".next-step").classList.add("current-step");
            $(".next-step").classList.remove("next-step");

            if (i == ss.length - 2) {
              $(".swiper-wrapper").children[0].classList.add("next-step");
            } else {
              if (i == ss.length - 1) {
                $(".swiper-wrapper").children[1].classList.add("next-step");
              } else {
                $(".swiper-wrapper").children[i + 2].classList.add("next-step");
              }
            }
            break;
          }
        }
      });
    }
  }, [render]);

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <>
      <article>
        <section className="kv">
          <figure>
            {/*<img src={kv} alt="" />*/}
            <div>
              <video
                id="video"
                autoPlay={true}
                muted={true}
                loop={true}
                playsInline={true}
                preload="auto"
                width="100%"
                height="100%"
              >
                <source src={isMobile() ? new_mobile_video : heroduck_main_mp4} type="video/mp4" />
                {/*<source src={heroduck_webm} type="video/webm" />*/}
              </video>
            </div>
          </figure>
        </section>
        {/*<section className="feature00">*/}
        {/*  <Countdown />*/}
        {/*</section>*/}
        {/*<section className="video-box">*/}
        {/*  <div>*/}
        {/*    <video*/}
        {/*      id="video"*/}
        {/*      autoPlay={true}*/}
        {/*      muted={true}*/}
        {/*      loop={true}*/}
        {/*      playsInline={true}*/}
        {/*      preload="auto"*/}
        {/*      width="100%"*/}
        {/*      height="100%"*/}
        {/*    >*/}
        {/*      <source src={heroduck_sub_mp4} type="video/mp4" />*/}
        {/*      /!*<source src={heroduck_webm} type="video/webm" />*!/*/}
        {/*    </video>*/}
        {/*  </div>*/}
        {/*</section>*/}
        <section className="feature01">
          <h2 className="tit">COMING SOON</h2>
          <div className="desc">
            <a href={manual_pdf} target="_blank">
              <p style={{ fontWeight: "bold", cursor: "pointer" }}>Find more </p>
            </a>
          </div>
        </section>
        {/*<section className="feature01">*/}
        {/*  <h2 className="tit">*/}
        {/*    Hero Duck<strong>{t(`section_01`)}</strong>*/}
        {/*  </h2>*/}
        {/*  <div className="desc">*/}
        {/*    <p>{t(`section_02`)}</p>*/}
        {/*    <p>{t(`section_03`)}</p>*/}
        {/*    <p>{t(`section_04`)}</p>*/}
        {/*  </div>*/}
        {/*  <ul>*/}
        {/*    <li>*/}
        {/*      <img style={styleBorderRadius} src={heroduck_03_img} alt="" />*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <img style={styleBorderRadius} src={heroduck_05_img} alt="" />*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <img style={styleBorderRadius} src={heroduck_01_img} alt="" />*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <img style={styleBorderRadius} src={heroduck_02_img} alt="" />*/}
        {/*    </li>*/}
        {/*    <li>*/}
        {/*      <img style={styleBorderRadius} src={heroduck_10_img} alt="" />*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</section>*/}
        {/*<section className="feature02">*/}
        {/*  <h2 className="tit">*/}
        {/*    Story<strong>{t(`section_05`)}</strong>*/}
        {/*  </h2>*/}
        {/*  <div className="desc">*/}
        {/*    <p>{t(`section_06`)}</p>*/}
        {/*    <p>{t(`section_07`)}</p>*/}
        {/*    <p>{t(`section_08`)}</p>*/}
        {/*    <p>{t(`section_09`)}</p>*/}
        {/*    <p>{t(`section_10`)}</p>*/}
        {/*  </div>*/}
        {/*</section>*/}
        {/*<section className="feature03">*/}
        {/*  <figure>*/}
        {/*    <img src={img_banner} alt="" />*/}
        {/*  </figure>*/}
        {/*</section>*/}
        {/*<section className="feature04" id="gallery">*/}
        {/*  <h2 className="tit black">GALLERY</h2>*/}
        {/*  <div className="thumb-slide">*/}
        {/*    <ul className="slide">*/}
        {/*      <div style={{ display: "flex", justifyContent: "center" }}>*/}
        {/*        <li>*/}
        {/*          <figure>*/}
        {/*            <img*/}
        {/*              className="gallery-img"*/}
        {/*              style={styleBorderRadius}*/}
        {/*              src={heroduck_01_img}*/}
        {/*              alt=""*/}
        {/*            />*/}
        {/*          </figure>*/}
        {/*        </li>*/}
        {/*      </div>*/}
        {/*    </ul>*/}
        {/*    <div className="bx-pager">*/}
        {/*      <a data-slide-index="1" href="">*/}
        {/*        <img style={styleBorderRadius} src={heroduck_02_img} alt="" />*/}
        {/*      </a>*/}
        {/*      <a data-slide-index="2" href="">*/}
        {/*        <img style={styleBorderRadius} src={heroduck_03_img} alt="" />*/}
        {/*      </a>*/}
        {/*      <a data-slide-index="3" href="">*/}
        {/*        <img style={styleBorderRadius} src={heroduck_04_img} alt="" />*/}
        {/*      </a>*/}
        {/*      <a data-slide-index="4" href="">*/}
        {/*        <img style={styleBorderRadius} src={heroduck_05_img} alt="" />*/}
        {/*      </a>*/}
        {/*      <a data-slide-index="5" href="">*/}
        {/*        <img style={styleBorderRadius} src={heroduck_06_img} alt="" />*/}
        {/*      </a>*/}
        {/*      <a data-slide-index="0" href="">*/}
        {/*        <img style={styleBorderRadius} src={heroduck_07_img} alt="" />*/}
        {/*      </a>*/}
        {/*      <a data-slide-index="1" href="">*/}
        {/*        <img style={styleBorderRadius} src={heroduck_08_img} alt="" />*/}
        {/*      </a>*/}
        {/*      <a data-slide-index="2" href="">*/}
        {/*        <img style={styleBorderRadius} src={heroduck_09_img} alt="" />*/}
        {/*      </a>*/}
        {/*      <a data-slide-index="3" href="">*/}
        {/*        <img style={styleBorderRadius} src={heroduck_10_img} alt="" />*/}
        {/*      </a>*/}
        {/*      /!*<a data-slide-index="4" href="">*!/*/}
        {/*      /!*  <img src={nft02} alt="" />*!/*/}
        {/*      /!*</a>*!/*/}
        {/*      /!*<a data-slide-index="5" href="">*!/*/}
        {/*      /!*  <img src={nft03} alt="" />*!/*/}
        {/*      /!*</a>*!/*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}
        {/*<section className="feature05" id="roadmap">*/}
        {/*  <div className="feature05-1">*/}
        {/*    <h2 className="tit">*/}
        {/*      Roadmap*/}
        {/*      <strong className="eng">*/}
        {/*        <Trans i18nKey="section_11" />*/}
        {/*      </strong>*/}
        {/*    </h2>*/}
        {/*    <p>*/}
        {/*      <span className="pc">*/}
        {/*        {currentLang === "Kor" ? (*/}
        {/*          <img src={text_road_map_img} alt="" />*/}
        {/*        ) : (*/}
        {/*          <img src={road_map_ko_img} />*/}
        {/*        )}*/}
        {/*      </span>*/}
        {/*      <span className="mobile">*/}
        {/*        {currentLang === "Kor" ? (*/}
        {/*          <img src={text_road_map_img_m_ko} alt="" />*/}
        {/*        ) : (*/}
        {/*          <img src={text_road_map_img_m} alt="" />*/}
        {/*        )}*/}
        {/*      </span>*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*  <div className="feature05-2">*/}
        {/*    <h2 className="tit">TREASURE LABS</h2>*/}
        {/*    <p>*/}
        {/*      <span className="pc">*/}
        {/*        <img src={text_chart_img} alt="" />*/}
        {/*      </span>*/}
        {/*      <span className="mobile">*/}
        {/*        <img src={text_chart_img_m} alt="" />*/}
        {/*      </span>*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</section>*/}
        {/*/!*<section className="feature06" id="team">*!/*/}
        {/*/!*  <h2 className="tit">Partners</h2>*!/*/}
        {/*/!*</section>*!/*/}
        {/*<section className="feature07" id="faq">*/}
        {/*  <h2 className="tit">FAQ</h2>*/}
        {/*  <ul className="qna-list">*/}
        {/*    {qnaLists.map((item, i) => {*/}
        {/*      return (*/}
        {/*        <li*/}
        {/*          className={select === item.idx ? "on" : ""}*/}
        {/*          onClick={() => {*/}
        {/*            console.log(item.idx, "idx");*/}
        {/*            setSelect(item.idx);*/}

        {/*            if (select && select === item.idx) {*/}
        {/*              setSelect(0);*/}
        {/*            }*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <div className="question">*/}
        {/*            <a>{item.question}</a>*/}
        {/*          </div>*/}
        {/*          <SlideDown className={"my-dropdown-slidedown"}>*/}
        {/*            <div className="answer">{item.answer}</div>*/}
        {/*          </SlideDown>*/}
        {/*        </li>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </ul>*/}
        {/*</section>*/}
        {/*<div id="waveform" />*/}
      </article>
    </>
  );
};

export default Home;
