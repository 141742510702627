import { atom } from "recoil";

export const isPossibleMintAtom = atom<boolean>({
  key: "isPossibleMintAtom",
  default: false,
});

export const currentLangAtom = atom({
  key: "currentLanguage",
  default: "Eng",
});
