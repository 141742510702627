import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import PublicRouter from "./common/publicRouter";
import Footer from "./components/Footer";

function App() {
  return (
    <main>
      <div id="contents">
        {/*<Header />*/}
        <PublicRouter />
        <Footer />
      </div>
    </main>
  );
}

export default App;
