import { router } from "./contants";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";

const PublicRouter = () => {
  const { HOME } = router;

  return (
    <Routes>
      <Route path={HOME} element={<Home />} />
    </Routes>
  );
};

export default PublicRouter;
